import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const CPanelComparisonPage = () => (
  <div>
    <SEO 
        title="Control Panel vs cPanel" 
        description="Compare the 2 standards–best site management tools"
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"><span className="block text-indigo-600 xl:inline">Sheephostingbay</span> Control Panel vs cPanel <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">Compare the 2 standards–best site management tools</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                        <p className="m-0">
                            <img src="https://sheephostingbay.duoservers.com/template2/img/hepsia-vs-cpanel-banner-white.png" alt="Sheephostingbay Web control panel"/>
                        </p>
                    </div>
                </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-indigo-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                The cPanel Control Panel was the indisputable pioneer amongst website hosting administration user interfaces 
                ome time ago. Once website hosting came into common use, the cPanel concept could not adjust to the new 
                circumstances and match the demands of the dynamic hosting industry anymore.
                </p>
                <p className="section-after-title">
                The Sheephostingbay Control Panel was made to run on a cloud hosting hosting setup and can easily easily be 
                changed anytime a brand new capability is added. It is a one–stop tool for maintaining your online presence 
                in addition to your domain names, sites, billing together with your technical support tickets.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 className="text-indigo-800">1. Domain name/billing/web site controls</h2>
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/hepsia-login.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    If you’d like to manage your domain names, web sites and invoicing from just one location without having 
                    to visit any extra panels, the Sheephostingbay Control Panel is just for you.
                    </p>
                    
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/cpanel-login.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    With the cPanel Control Panel, you’ll need to log into 2 different Control Panel interfaces – a billing 
                    panel from where you take care of domain names and payments and the cPanel Control Panel from which 
                    you control your sites.
                    </p>
                    
                </div>
            </div>
        </div>
        <hr/>
        <h2 className="text-indigo-800">2. File Arrangement</h2>
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/hepsia-file-structure.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    Using the Sheephostingbay Control Panel, each site name and subdomain will have its directory within the root 
                    hosting account directory. This way, all websites will be fully insulated from one another.
                    </p>
                    
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/cpanel-file-structure.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    In the cPanel Control Panel, you have just one directory for your main website and all of the the extra sites 
                    are situated inside this folder. This would make taking care of a variety of web sites from a single user 
                    interface very puzzling.
                    </p>
                    
                </div>
            </div>
        </div>
        <hr/>
        <h2 className="text-indigo-800">3. File Manager</h2>
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/hepsia-file-manager.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    The Sheephostingbay File Manager is built to perform much like your PC file explorer. Offering capabilities like drag 
                    and drop file file publishing, right–click navigation menus, zip/unzip options, and so on., it’ll make managing your 
                    files quick and simple. In addition, you will find an inbuilt WYSIWYG manager and a program code manager.
                    </p>
                    
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/cpanel-file-manager.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    The File Manager of cPanel is slow and constrained in functions. Adding many different files needs a great deal of time 
                    and and won’t offer drag–n–drop support. The in–built archive/unzip tool is unreliable and the file editing instruments 
                    supply you with solely general controls. There’s no fast access to the available file managing characteristics.
                    </p>
                    
                </div>
            </div>
        </div>
        <hr/>
        <h2 className="text-indigo-800">4. Free–of–cost Bonus Tools</h2>
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/hepsia-bonuses.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    Using the Sheephostingbay Control Panel, you you’ll have a wide range of complimentary benefits developed by us. They are 
                    offered totally free with each website hosting package, which uses Sheephostingbay’s Control Panel. With each package, 
                    you can employ tools like the Online Site Installer, the Applications Installer, the PHP 1 Click Framework Installer, 
                    the Complimentary Web Site Building Tool, a selection of Advanced Tools, and more.
                    </p>
                    
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/cpanel-bonuses.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    The cPanel Control Panel is offered through a commercial license and add–on gifts will be integrated at an added fee. Because 
                    of this, even if your company gives free extras within the cPanel, their price will be included in the cost of your hosting 
                    offer. Also, the quantity and sort of no–cost add–ons will vary from one web hosting supplier to another.
                    </p>
                    
                </div>
            </div>
        </div>
        <hr/>
        <h2 className="text-indigo-800">5. Control Panel Operational Speeds</h2>
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/hepsia-speed.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    The Sheephostingbay Control Panel works with our own website hosting. It has been enhanced to work on our setup of hardware parts 
                    and to function with our selection of software programs. This makes it operate faster when compared with almost any other Control 
                    Panel solution in the marketplace. And it is safer in terms of performance too.
                    </p>
                    
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/cpanel-speed.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    cPanel is still working on a system from 2000’s and will truly be sluggish at times. The cPanel team of developers has carried out an 
                    important step with the release of cPanel accelerated. Nevertheless, in comparison tests, widespread web site management functions 
                    typically demonstrate greater results with other Control Panel solutions.
                    </p>
                    
                </div>
            </div>
        </div>
        <hr/>
        <h2 className="text-indigo-800">6. Multi–domain name Management</h2>
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/hepsia-domain-control.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    The Sheephostingbay Control Panel helps you smoothly maintain many web sites as well as their domains from one location. Each 
                    individual web site is going to have its own special directory in the root directory of your web hosting account and will be 
                    completely separate from the rest. This way, you need utilize just one account to control as many domains and web sites as 
                    you want.
                    </p>
                    
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/cpanel-domain-control.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    If you try to run multiple domains in a cPanel hosting account, you will be confronted with a very perplexing directory structure. 
                    In general, cPanel hosting accounts are built to handle one domain name. In this manner, if you want to work with 10 domains at 
                    the same time, for example, you will have to setup 10 separate accounts. Otherwise, all extra domains’ files will be situated 
                    in the folder of the main domain.
                    </p>
                    
                </div>
            </div>
        </div>
        <hr/>
        <h2 className="text-indigo-800">7. Control Panel Menu</h2>
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/hepsia-navigation.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    The Sheephostingbay Control Panel is absolutely easy to get around. You’ll find a helpful top navigation menu, from which you can 
                    get to every part of the Control Panel, without having to go back to the homepage each time. Plus, we’ve integrated a simple 
                    stats module on the index page, so any time you log in, you’ll have a glance at exactly how many visitors have gone to your 
                    web site.
                    </p>
                    
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/cpanel-navigation.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    On the cPanel home page, you will find icons to all parts of the Control Panel. It means that, even if you never work with an area, 
                    you’ll still find it on the index page. In addition, to navigate between sections, you have to return straight back to the main 
                    page and then head somewhere else.
                    </p>
                    
                </div>
            </div>
        </div>
        <hr/>
        <h2 className="text-indigo-800">8. Demo Control Panel Accounts</h2>
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/hepsia-demo-accounts.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    The Sheephostingbay Control Panel boasts a full demo that offers virtually every menu and functionality it has. You could start creating 
                    a web–site, install web apps, set up email accounts, etcetera. Thus, you can get a a lot more in–depth idea of its interface and 
                    characteristics before you sign up.
                    </p>
                    
                </div>
            </div>

            <div className="my-2 px-2 sm:w-1/2 w-full overflow-hidden text-center">
                <div className="max-w-md py-4 px-8 bg-white mt-20 mb-5">
                <div className="flex justify-center md:justify-end -mt-16">
                        <img alt="Online Site Installer" src="https://sheephostingbay.duoservers.com/template2/img/cpanel-demo-accounts.webp" />
                    </div>
                    <h2 className="text-indigo-800 text-base mb-0 font-semibold">Web Applications</h2>
                    <p className="text-gray-600 text-sm subpixel-antialiased">
                    The cPanel Control Panel demo version is basically a screenshot of what’s inside the primary menu. Nearly every functionality that is 
                    displayed there is disabled. Thus, when you sign up, you will have no clue how the included tools and features of the Control Panel 
                    in fact operate.
                    </p>
                    
                </div>
            </div>
        </div>
        <hr/>
        <div className="flex flex-wrap -mx-2 overflow-hidden">
            <div className="my-2 px-2 w-full overflow-hidden text-center">
                <p className="text-gray-600 text-sm subpixel-antialiased">
                    Using the Sheephostingbay Control Panel, managing your websites will be easy and enjoyable. 
                    <br/>Simply take a look at the Web Control Panel demo and investigate the included 
                    features and capabilities to see on your own.
                </p>
            </div>
        </div>


      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default CPanelComparisonPage
